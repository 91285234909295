export const QUERY_KEYS = {
    GET_REFERENCE_LINKS: 'getReferenceLinks',
    GET_PAIR_ROOM: 'getPairRoom',
    GET_PAIR_ROOM_HISTORY: 'getPairRoomHistory',
    GET_REPOSITORIES: 'getRepositories',
    GET_BRANCHES: 'getBranches',
    GET_CATEGORIES: 'getCategories',
    GET_SIGN_IN: 'getSignIn',
    GET_SIGN_OUT: 'getSignOut',
};
