import { jsx as _jsx } from "react/jsx-runtime";
import { AiFillDelete } from 'react-icons/ai';
import { FaPencilAlt, FaCheck } from 'react-icons/fa';
import { GiCancel } from 'react-icons/gi';
import * as S from './CategoryFilter.styles';
const IconButton = ({ onClick, icon }) => {
    const GET_ICON = {
        CHECK: _jsx(FaCheck, {}),
        EDIT: _jsx(FaPencilAlt, {}),
        DELETE: _jsx(AiFillDelete, {}),
        CANCEL: _jsx(GiCancel, {}),
    };
    return (_jsx(S.IconsButton, { onClick: (event) => {
            event.stopPropagation();
            onClick && onClick();
        }, children: GET_ICON[icon] }));
};
export default IconButton;
