import { useEffect } from 'react';
const useEscapeKey = (isOpen, close) => {
    useEffect(() => {
        if (!isOpen)
            return;
        const handleEscapePress = (event) => {
            if (event.key === 'Escape')
                close();
        };
        document.addEventListener('keydown', handleEscapePress, true);
        return () => document.removeEventListener('keydown', handleEscapePress, true);
    }, [isOpen, close]);
};
export default useEscapeKey;
