import { useState } from 'react';
const useInput = (initialValue = '') => {
    const [value, setValue] = useState(initialValue);
    const [status, setStatus] = useState('DEFAULT');
    const [message, setMessage] = useState('');
    const handleChange = (event, validateValue) => {
        if (validateValue) {
            const { status, message } = validateValue;
            setStatus(status);
            setMessage(message);
        }
        setValue(event.target.value);
    };
    const resetValue = () => {
        setValue(initialValue);
        setStatus('DEFAULT');
        setMessage('');
    };
    return { value, status, message, handleChange, resetValue };
};
export default useInput;
