import styled from 'styled-components';
const positionMapper = {
    LEFT: 'start',
    CENTER: 'center',
    RIGHT: 'end',
};
export const Layout = styled.div `
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  justify-content: ${({ $direction, $position }) => $direction === 'ROW' && positionMapper[$position]};
  align-items: ${({ $direction, $position }) => $direction === 'COLUMN' && positionMapper[$position]};
  gap: 1.6rem;
`;
