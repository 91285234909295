import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { validateTimerDuration } from '@/validations/validateTimerDuration';
import Button from '@/components/common/Button/Button';
import Input from '@/components/common/Input/Input';
import useToastStore from '@/stores/toastStore';
import useClickOutside from '@/hooks/common/useClickOutside';
import useInput from '@/hooks/common/useInput';
import useModal from '@/hooks/common/useModal';
import useUpdateTimerDuration from '@/queries/PairRoomOnboarding/useUpdateTimerDuration';
import { BUTTON_TEXT } from '@/constants/button';
import * as S from './TimerEditPanel.styles';
const TimerEditPanel = ({ isActive }) => {
    const panelRef = useRef(null);
    const { accessCode } = useParams();
    const { addToast } = useToastStore();
    const { isModalOpen: isPanelOpen, openModal: openPanel, closeModal: closePanel } = useModal();
    const { value, handleChange, resetValue } = useInput();
    const { handleUpdateTimerDuration } = useUpdateTimerDuration(() => addToast({ status: 'SUCCESS', message: '타이머 시간이 변경되었습니다.' }));
    const handleButtonClick = () => {
        if (isActive) {
            addToast({ status: 'ERROR', message: '타이머 작동 중에는 타이머 시간을 변경할 수 없습니다.' });
            return;
        }
        openPanel();
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (!value || !accessCode)
            return;
        handleUpdateTimerDuration(value, accessCode);
        resetValue();
        closePanel();
    };
    useClickOutside(panelRef, () => closePanel());
    const isButtonDisabled = value === '' || !validateTimerDuration(value);
    return (_jsxs(S.Layout, { children: [_jsx(S.Icon, { onClick: handleButtonClick }), isPanelOpen && (_jsxs(S.Panel, { ref: panelRef, children: [_jsx(S.Title, { children: "\uD0C0\uC774\uBA38 \uC2DC\uAC04 \uBCC0\uACBD" }), _jsxs(S.Form, { onSubmit: handleSubmit, children: [_jsx(Input, { id: "timer", value: value, placeholder: "\uD0C0\uC774\uBA38 \uC2DC\uAC04 (\uBD84)", onChange: handleChange }), _jsxs(S.ButtonContainer, { children: [_jsx(Button, { type: "button", color: "secondary", size: "sm", filled: false, rounded: true, onClick: closePanel, children: BUTTON_TEXT.CLOSE }), _jsx(Button, { type: "submit", color: "secondary", size: "sm", rounded: true, disabled: isButtonDisabled, children: BUTTON_TEXT.COMPLETE })] })] })] }))] }));
};
export default TimerEditPanel;
