import fetcher from '@/apis/fetcher';
import { ERROR_MESSAGES } from '@/constants/message';
const API_URL = process.env.REACT_APP_API_URL;
export const getSignInGithub = async () => {
    const response = await fetcher.get({
        url: `${API_URL}/sign-in/oauth/github`,
        errorMessage: ERROR_MESSAGES.SIGN_IN,
    });
    return await response.json();
};
export const getIsUserLoggedIn = async () => {
    const response = await fetcher.get({
        url: `${API_URL}/sign-in/check`,
        errorMessage: ERROR_MESSAGES.CHECK_USER_LOGIN,
    });
    return await response.json();
};
export const addSignUp = async (username) => {
    const response = await fetcher.post({
        url: `${API_URL}/sign-up`,
        body: JSON.stringify({ username }),
        errorMessage: ERROR_MESSAGES.SIGN_UP,
    });
    return await response.json();
};
export const getSignInCallback = async () => {
    const response = await fetcher.get({
        url: `${API_URL}/sign-in/callback`,
        errorMessage: ERROR_MESSAGES.SIGN_IN,
    });
    return await response.json();
};
