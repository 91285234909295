import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { validateTimerDuration } from '@/validations/validateTimerDuration';
import Button from '@/components/common/Button/Button';
import Input from '@/components/common/Input/Input';
import InformationBox from '@/components/PairRoomOnboarding/InformationBox/InformationBox';
import * as S from './TimerDurationInput.styles';
const OPTIONS = [
    { label: '10분', value: '10' },
    { label: '15분', value: '15' },
    { label: '30분', value: '30' },
];
const TimerDurationInput = ({ timerDuration, onTimerDuration }) => {
    const [isCustom, setIsCustom] = useState(false);
    const handleIsCustomTime = () => {
        if (!isCustom)
            setIsCustom(true);
        onTimerDuration('');
    };
    const handleOptionTime = (option) => {
        if (isCustom)
            setIsCustom(false);
        onTimerDuration(option);
    };
    const handleCustomTime = (event) => {
        onTimerDuration(event.target.value);
    };
    return (_jsxs(S.Layout, { children: [_jsxs(S.HeaderContainer, { children: [_jsxs(S.TitleContainer, { children: [_jsx(S.Title, { children: "\uD0C0\uC774\uBA38 \uC124\uC815" }), _jsx(S.SubTitle, { children: "\uD0C0\uC774\uBA38 \uC2DC\uAC04\uC744 \uC124\uC815\uD574 \uC8FC\uC138\uC694." })] }), _jsx(InformationBox, { title: "\uC65C \uD0C0\uC774\uBA38 \uC2DC\uAC04\uC744 \uC124\uC815\uD574\uC57C \uD558\uB098\uC694?", description: "\uC815\uAE30\uC801\uC778 \uC5ED\uD560 \uAD50\uB300\uB294 \uD53C\uB4DC\uBC31\uC744 \uC8FC\uACE0\uBC1B\uC744 \uC218 \uC788\uB294 \uC790\uC5F0\uC2A4\uB7EC\uC6B4 \uAE30\uD68C\uB97C \uC81C\uACF5\uD569\uB2C8\uB2E4. \uC774\uB294 \uCF54\uB4DC \uD488\uC9C8\uC744 \uB192\uC774\uACE0, \uBB38\uC81C\uB97C\n          \uC870\uAE30\uC5D0 \uBC1C\uACAC\uD558\uC5EC \uC218\uC815\uD560 \uC218 \uC788\uAC8C \uD569\uB2C8\uB2E4." })] }), _jsxs(S.ButtonContainer, { children: [OPTIONS.map((option) => (_jsx(Button, { color: "primary", size: "md", filled: timerDuration === option.value, onClick: () => handleOptionTime(option.value), children: option.label }, option.value))), _jsxs(S.InputContainer, { children: [_jsx(Button, { color: "primary", size: "md", filled: isCustom, onClick: handleIsCustomTime, children: "\uC9C1\uC811 \uC124\uC815" }, "\uC9C1\uC811 \uC124\uC815"), isCustom && (_jsx(Input, { width: "16rem", "$css": S.inputStyles, value: timerDuration, placeholder: "\uD0C0\uC774\uBA38 \uC2DC\uAC04 (\uBD84)", status: !validateTimerDuration(timerDuration) ? 'ERROR' : 'DEFAULT', message: !validateTimerDuration(timerDuration) ? '1 이상 99 이하의 숫자를 입력해 주세요.' : '', disabled: !isCustom, onChange: handleCustomTime }))] })] })] }));
};
export default TimerDurationInput;
