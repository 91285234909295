import { create } from 'zustand';
import { getIsUserLoggedIn } from '@/apis/oauth';
const useUserStatusStore = create((set) => ({
    userStatus: 'SIGNED_OUT',
    setUserStatus: (userStatus) => {
        set(() => ({
            userStatus,
        }));
    },
}));
getIsUserLoggedIn().then((response) => {
    useUserStatusStore.getState().setUserStatus(response.signedIn ? 'SIGNED_IN' : 'SIGNED_OUT');
});
export default useUserStatusStore;
