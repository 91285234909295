import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogoIconWithTitle } from '@/assets';
import { validateName } from '@/validations/validatePairName';
import Button from '@/components/common/Button/Button';
import Input from '@/components/common/Input/Input';
import useUserStatusStore from '@/stores/userStatusStore';
import useInput from '@/hooks/common/useInput';
import useSignUpHandler from '@/hooks/member/useSignUpHandler';
import * as S from './SignUp.styles';
const SignUp = () => {
    const { userStatus } = useUserStatusStore();
    const navigate = useNavigate();
    useEffect(() => {
        if (userStatus === 'SIGNED_IN') {
            navigate('/main', { replace: true });
        }
    }, [userStatus, navigate]);
    const { value: username, status: usernameStatus, message: usernameMessage, handleChange: onUsernameChange, } = useInput();
    const { handleSignUp } = useSignUpHandler();
    const handleChange = (event) => {
        onUsernameChange(event, validateName(event.target.value));
    };
    return (_jsxs(S.Layout, { children: [_jsx(S.LogoIconWithTitle, { src: LogoIconWithTitle, alt: "logo_icon_with_title" }), _jsx(S.Title, { children: "\uCCAB \uBC29\uBB38\uC774\uC2DC\uB124\uC694! \uB2F9\uC2E0\uC744 \uC5B4\uB5BB\uAC8C \uBD88\uB7EC\uC57C \uD560\uAE4C\uC694?" }), _jsx(S.InputWrapper, { children: _jsx(Input, { value: username, status: usernameStatus, message: usernameMessage, title: "\uC774\uB984(\uB610\uB294 \uB2C9\uB124\uC784)", placeholder: "\uC774\uB984(\uB610\uB294 \uB2C9\uB124\uC784)\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694.", onChange: handleChange }) }), _jsx(S.ButtonWrapper, { children: _jsx(Button, { size: "lg", rounded: true, onClick: () => handleSignUp(username), children: "\uACC4\uC815 \uB9CC\uB4E4\uAE30 \uD83E\uDD73" }) })] }));
};
export default SignUp;
