// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/Pretendard-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/Pretendard-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/Pretendard-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("@/assets/fonts/Pretendard-SemiBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("@/assets/fonts/Pretendard-Bold.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Pretendard';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'Pretendard';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Pretendard';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Pretendard';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'Pretendard';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2');
  font-weight: 700;
}

html {
  font-family: 'Pretendard';
  font-size: 62.5%;
}

body {
  font-family: 'Pretendard';
}
`, "",{"version":3,"sources":["webpack://./src/styles/font.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,4DAAiE;EACjE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,4DAAmE;EACnE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,4DAAkE;EAClE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,4DAAoE;EACpE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,4DAAgE;EAChE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["@font-face {\n  font-family: 'Pretendard';\n  src: url('@/assets/fonts/Pretendard-Light.woff2') format('woff2');\n  font-weight: 300;\n}\n\n@font-face {\n  font-family: 'Pretendard';\n  src: url('@/assets/fonts/Pretendard-Regular.woff2') format('woff2');\n  font-weight: 400;\n}\n\n@font-face {\n  font-family: 'Pretendard';\n  src: url('@/assets/fonts/Pretendard-Medium.woff2') format('woff2');\n  font-weight: 500;\n}\n\n@font-face {\n  font-family: 'Pretendard';\n  src: url('@/assets/fonts/Pretendard-SemiBold.woff2') format('woff2');\n  font-weight: 600;\n}\n\n@font-face {\n  font-family: 'Pretendard';\n  src: url('@/assets/fonts/Pretendard-Bold.woff2') format('woff2');\n  font-weight: 700;\n}\n\nhtml {\n  font-family: 'Pretendard';\n  font-size: 62.5%;\n}\n\nbody {\n  font-family: 'Pretendard';\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
