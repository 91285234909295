import styled from 'styled-components';
export const Layout = styled.div `
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const Title = styled.h3 `
  color: ${({ theme }) => theme.color.primary[700]};
  font-size: ${({ theme }) => theme.fontSize.h3};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;
export const SubTitle = styled.p `
  color: ${({ theme }) => theme.color.primary[600]};
  font-size: ${({ theme }) => theme.fontSize.h6};
`;
