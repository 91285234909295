import * as Sentry from '@sentry/react';
const fetcher = {
    async request({ url, method, body, headers, errorMessage }) {
        try {
            const response = await fetch(url, {
                method,
                headers: headers && headers,
                body: body && body,
                credentials: 'include',
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || errorMessage);
            }
            return response;
        }
        catch (error) {
            if (!(error instanceof Error))
                error.message;
            Sentry.captureException(error);
            throw error;
        }
    },
    get(props) {
        return this.request({ ...props, method: 'GET' });
    },
    post(props) {
        return this.request({
            ...props,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        });
    },
    delete(props) {
        return this.request({ ...props, method: 'DELETE', headers: { 'Content-Type': 'application/json' } });
    },
    patch(props) {
        return this.request({
            ...props,
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
        });
    },
    put(props) {
        return this.request({ ...props, method: 'PUT' });
    },
};
export default fetcher;
