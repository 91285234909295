import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import { LogoIcon } from '@/assets';
import useUserStatusStore from '@/stores/userStatusStore';
import { getMember } from '@/apis/member';
import useSignInHandler from '@/hooks/member/useSignInHandler';
import useSignOutHandler from '@/hooks/member/useSignOutHandler';
import { theme } from '@/styles/theme';
import * as S from './Header.styles';
const Header = () => {
    const { userStatus } = useUserStatusStore();
    const { handleSignOut } = useSignOutHandler();
    const { handleSignInGithub } = useSignInHandler();
    const [username, setUsername] = useState('');
    useEffect(() => {
        if (userStatus === 'SIGNED_IN') {
            getMember()
                .then((data) => setUsername(data.username))
                .catch(console.error);
        }
    }, [userStatus]);
    return (_jsxs(S.Layout, { children: [_jsx(Link, { to: "/", children: _jsx(S.Logo, { src: LogoIcon, alt: "logo_icon_with_title" }) }), _jsxs(S.LinkContainer, { children: [_jsxs(S.HowToPairLinkContainer, { children: [_jsx(Link, { to: "/how-to-pair", children: _jsx(S.HowToPairTextLink, { children: "\uD398\uC5B4 \uD504\uB85C\uADF8\uB798\uBC0D\uC774\uB780?" }) }), _jsx(Link, { to: "/how-to-pair", children: _jsx(S.HowToPairIconLink, { children: _jsx(HiQuestionMarkCircle, { size: theme.iconSize.sm }) }) })] }), userStatus === 'SIGNED_IN' ? (_jsxs(_Fragment, { children: [_jsx(S.LoginText, { onClick: handleSignOut, children: "\uB85C\uADF8\uC544\uC6C3" }), _jsx(S.Username, { children: username })] })) : (_jsx(S.LoginText, { onClick: handleSignInGithub, children: "Github\uB85C \uB85C\uADF8\uC778" }))] })] }));
};
export default Header;
