import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Spinner from '@/components/common/Spinner/Spinner';
import CreateBranchModal from '@/components/PairRoomOnboarding/CreateBranchModal/CreateBranchModal';
import InformationBox from '@/components/PairRoomOnboarding/InformationBox/InformationBox';
import RepositoryButton from '@/components/PairRoomOnboarding/RepositoryButton/RepositoryButton';
import useModal from '@/hooks/common/useModal';
import useGetRepositories from '@/queries/PairRoomOnboarding/useGetRepositories';
import * as S from './MissionSelectInput.styles';
const MissionSelectInput = ({ onCreateBranch }) => {
    const [currentRepository, setCurrentRepository] = useState('');
    const { isModalOpen, closeModal, openModal } = useModal();
    const { repositories, isFetching } = useGetRepositories();
    const handleSelectRepository = (currentRepository) => {
        setCurrentRepository(currentRepository);
        openModal();
    };
    return (_jsxs(S.Layout, { children: [_jsxs(S.HeaderContainer, { children: [_jsxs(S.TitleContainer, { children: [_jsx(S.Title, { children: "\uBBF8\uC158 \uC120\uD0DD" }), _jsx(S.SubTitle, { children: "\uAD6C\uD604\uD574 \uBCFC \uBBF8\uC158\uC758 \uB808\uD3EC\uC9C0\uD1A0\uB9AC\uB97C \uC120\uD0DD\uD574 \uC8FC\uC138\uC694." })] }), _jsx(InformationBox, { title: "\uBBF8\uC158\uC744 \uC120\uD0DD\uD558\uBA74 \uC5B4\uB5BB\uAC8C \uB418\uB098\uC694?", description: "\uBBF8\uC158\uC744 \uC120\uD0DD\uD558\uACE0 \uD574\uB2F9 \uBBF8\uC158 \uB808\uD3EC\uC9C0\uD1A0\uB9AC\uC5D0 \uC6D0\uD558\uB294 \uC774\uB984\uC73C\uB85C \uBE0C\uB79C\uCE58\uB97C \uC0DD\uC131\uD558\uC138\uC694. \uC0DD\uC131\uB41C \uBE0C\uB79C\uCE58\uB85C \uC774\uB3D9\uD558\uC5EC \uBBF8\uC158 \uCF54\uB4DC\uB97C \uAD00\uB9AC\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4." })] }), _jsx(S.RepositoryContainer, { children: isFetching ? (_jsx(Spinner, { size: "sm" })) : (repositories.map((repository) => {
                    return (_jsx(RepositoryButton, { name: repository.name, id: repository.id, onSelect: handleSelectRepository }, repository.id));
                })) }), _jsx(CreateBranchModal, { isOpen: isModalOpen, close: closeModal, currentRepository: currentRepository, onComplete: (branchName) => onCreateBranch(currentRepository, branchName) })] }));
};
export default MissionSelectInput;
