import { jsx as _jsx } from "react/jsx-runtime";
import * as S from './Toast.styles';
const TOAST_IMOJI = {
    SUCCESS: '✅',
    INFO: '📖',
    WARNING: '👀',
    ERROR: '⛔️',
};
const Toast = ({ isOpen, isPush, message, status = 'ERROR' }) => {
    return (_jsx(S.Layout, { "$isOpen": isOpen, "$isPush": isPush, "$status": status, children: `${TOAST_IMOJI[status]} ${message}` }));
};
export default Toast;
