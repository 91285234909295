import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect } from 'react';
import { PairRoomCard } from '@/components/PairRoom/PairRoomCard';
import TimerEditPanel from '@/components/PairRoom/TimerCard/TimerEditPanel/TimerEditPanel';
import useTimer from '@/hooks/PairRoom/useTimer';
import useTitleTime from '@/hooks/title/useTitleTime';
import { formatTime } from '@/utils/Timer/formatTime';
import * as S from './TimerCard.styles';
const TimerCard = ({ defaultTime, defaultTimeleft, onTimerStop, onUpdateTimeLeft }) => {
    const { timeLeft, isActive, handleStart, handlePause } = useTimer(defaultTime, defaultTimeleft, onTimerStop);
    const timeLeftRef = useRef(timeLeft);
    timeLeftRef.current = timeLeft;
    useEffect(() => {
        const handleBeforeMove = (event) => {
            onUpdateTimeLeft(timeLeftRef.current);
            handlePause();
            event.preventDefault();
        };
        window.addEventListener('beforeunload', handleBeforeMove);
        window.addEventListener('popstate', handleBeforeMove);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeMove);
            window.removeEventListener('popstate', handleBeforeMove);
        };
    }, []);
    // useBeforeUnload((event) => {
    //   onUpdateTimeLeft(timeLeftRef.current);
    //   handlePause();
    //   event.preventDefault();
    // });
    const { minutes, seconds } = formatTime(timeLeft);
    useTitleTime(minutes, seconds);
    return (_jsxs(PairRoomCard, { children: [_jsx(TimerEditPanel, { isActive: isActive }), _jsxs(S.Layout, { children: [_jsx(S.ProgressBar, { "$progress": (timeLeft / defaultTime) * 100, children: _jsxs(S.Timer, { children: [_jsxs(S.TimerTextContainer, { children: [_jsx(S.TimerText, { children: minutes }), "\uBD84(m)"] }), _jsx(S.TimerText, { children: ":" }), _jsxs(S.TimerTextContainer, { children: [_jsx(S.TimerText, { children: seconds }), "\uCD08(s)"] })] }) }), _jsxs(S.IconContainer, { children: [_jsx(S.IconButton, { disabled: isActive, onClick: handleStart, children: _jsx(S.PlayIcon, { "$isActive": !isActive }) }), _jsx(S.IconButton, { disabled: !isActive, onClick: handlePause, children: _jsx(S.PauseIcon, { "$isActive": isActive }) })] })] })] }));
};
export default TimerCard;
