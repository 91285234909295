import { jsx as _jsx } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import TodoItem from '@/components/PairRoom/TodoListCard/TodoItem/TodoItem';
import useDragAndDrop from '@/hooks/common/useDragAndDrop';
import useTodos from '@/queries/PairRoom/useTodos';
import * as S from './TodoList.styles';
const TodoList = () => {
    const { accessCode } = useParams();
    const { todos, handleUpdateOrder } = useTodos(accessCode || '');
    const { dragOverItem, handleDragStart, handleDragEnter, handleDrop } = useDragAndDrop(todos, handleUpdateOrder);
    return (_jsx(S.Layout, { children: todos.length > 0 ? (todos.map((todo) => (_jsx(TodoItem, { todo: todo, isDraggedOver: dragOverItem?.id === todo.id, onDragStart: handleDragStart, onDragEnter: handleDragEnter, onDrop: handleDrop }, todo.id)))) : (_jsx(S.EmptyText, { children: "\uC800\uC7A5\uB41C \uD22C\uB450 \uB9AC\uC2A4\uD2B8\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4." })) }));
};
export default TodoList;
