import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import Button from '@/components/common/Button/Button';
import Input from '@/components/common/Input/Input';
import { Modal } from '@/components/common/Modal';
import useToastStore from '@/stores/toastStore';
import useInput from '@/hooks/common/useInput';
import useGetPairRoomInformation from '@/queries/PairRoomOnboarding/useGetPairRoomInformation';
import { BUTTON_TEXT } from '@/constants/button';
const PairRoomEntryModal = ({ isOpen, closeModal }) => {
    const navigate = useNavigate();
    const { addToast } = useToastStore();
    const { value, status, message, handleChange } = useInput();
    const { refetch } = useGetPairRoomInformation(value);
    const enterPairRoom = async () => {
        const { error, isFetching, isSuccess } = await refetch();
        if (error) {
            addToast({ status: 'ERROR', message: '해당 코드와 일치하는 방이 없습니다 🥹' });
            return;
        }
        if (!isFetching && isSuccess) {
            navigate(`/room/${value}`);
        }
    };
    return (_jsxs(Modal, { isOpen: isOpen, close: closeModal, size: "60rem", children: [_jsx(Modal.Header, { title: "\uD398\uC5B4\uB8F8 \uCC38\uAC00\uD558\uAE30" }), _jsx(Modal.CloseButton, { close: closeModal }), _jsx(Modal.Body, { children: _jsx(Input, { placeholder: "\uCF54\uB4DC\uB97C \uC785\uB825\uD574 \uC8FC\uC138\uC694", label: "\uD398\uC5B4\uB8F8 \uCC38\uAC00 \uCF54\uB4DC", status: status, message: message, onChange: handleChange }) }), _jsxs(Modal.Footer, { children: [_jsx(Button, { onClick: closeModal, filled: false, children: BUTTON_TEXT.CLOSE }), _jsx(Button, { disabled: !value, onClick: () => enterPairRoom(), children: BUTTON_TEXT.COMPLETE })] })] }));
};
export default PairRoomEntryModal;
