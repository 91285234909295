import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BsArrowReturnRight } from 'react-icons/bs';
import { IoHomeSharp } from 'react-icons/io5';
import Button from '@/components/common/Button/Button';
import { Modal } from '@/components/common/Modal';
import useInput from '@/hooks/common/useInput';
import useGetBranches from '@/queries/PairRoomOnboarding/useGetBranches';
import { theme } from '@/styles/theme';
import * as S from './CreateBranchModal.styles';
const CreateBranchModal = ({ isOpen, close, currentRepository, onComplete }) => {
    const { value, status, message, handleChange, resetValue } = useInput();
    const { isAlreadyCreated } = useGetBranches(currentRepository);
    const validateBranchName = (name) => {
        if (isAlreadyCreated(name))
            return { status: 'ERROR', message: '중복된 브랜치 이름 입니다.' };
        return { status: 'DEFAULT', message: '' };
    };
    const handleClose = () => {
        resetValue();
        close();
    };
    return (_jsxs(Modal, { isOpen: isOpen, close: handleClose, size: "sm", children: [_jsx(Modal.CloseButton, { close: handleClose }), _jsxs(S.TitleContainer, { children: [_jsxs(S.TitleWrapper, { children: [_jsx(S.Title, { children: currentRepository }), _jsxs(S.RepositoryLink, { to: `https://github.com/coduo-missions/${currentRepository}`, target: "_blank", children: [_jsx(IoHomeSharp, {}), "\uBBF8\uC158 \uB0B4\uC6A9 \uD655\uC778\uD558\uAE30"] })] }), _jsx(S.SubTitle, { children: "\uBE0C\uB79C\uCE58\uB97C \uC0DD\uC131\uD558\uC5EC \uBBF8\uC158\uC744 \uC2DC\uC791\uD574\uC8FC\uC138\uC694." })] }), _jsx(Modal.Body, { children: _jsxs(S.ModalContainer, { children: [_jsx(S.MissionRepository, { children: currentRepository }), _jsxs(S.MissionBranchBox, { children: [_jsx(BsArrowReturnRight, { size: '3rem', color: theme.color.black[70] }), _jsx(S.MissionBranch, { value: value, onChange: (event) => handleChange(event, validateBranchName(event.target.value)) })] }), _jsx(S.Message, { children: message })] }) }), _jsx(Modal.Footer, { position: "CENTER", children: _jsx(Button, { disabled: status !== 'DEFAULT' || value === '', onClick: () => onComplete(value), children: "\uBE0C\uB79C\uCE58 \uC0DD\uC131\uD558\uAE30" }) })] }));
};
export default CreateBranchModal;
