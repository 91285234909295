import { useRef, useEffect } from 'react';
import { LogoIcon } from '@/assets';
const useNotification = () => {
    const notificationRef = useRef(null);
    const handleNotificationClick = (event) => {
        event.preventDefault();
        window.focus();
        notificationRef.current?.close();
    };
    const requestPermission = async () => {
        if (Notification.permission !== 'granted') {
            await Notification.requestPermission();
        }
    };
    const fireNotification = (title, body, options) => {
        if (Notification.permission === 'granted' && !document.hasFocus()) {
            const newOption = {
                body: body || ' ',
                badge: LogoIcon,
                icon: LogoIcon,
                ...options,
            };
            const notification = new Notification(title, newOption);
            notificationRef.current = notification;
            notification.onclick = handleNotificationClick;
        }
        else {
            console.warn('알림 권한이 허용되지 않았습니다.');
        }
    };
    useEffect(() => {
        requestPermission();
    }, []);
    return { fireNotification };
};
export default useNotification;
