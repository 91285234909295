import fetcher from '@/apis/fetcher';
import { ERROR_MESSAGES } from '@/constants/message';
const API_URL = process.env.REACT_APP_API_URL;
export const getPairRoom = async (accessCode) => {
    const response = await fetcher.get({
        url: `${API_URL}/pair-room/${accessCode}`,
        errorMessage: ERROR_MESSAGES.GET_PAIR_ROOM,
    });
    return await response.json();
};
export const getPairRoomHistory = async (accessCode) => {
    const response = await fetcher.get({
        url: `${API_URL}/${accessCode}/history/latest`,
        errorMessage: ERROR_MESSAGES.GET_PAIR_ROOM,
    });
    return await response.json();
};
export const addPairRoom = async ({ firstPair, secondPair }) => {
    const response = await fetcher.post({
        url: `${API_URL}/pair-room`,
        body: JSON.stringify({ firstPair, secondPair, status: 'IN_PROGRESS' }),
        errorMessage: '',
    });
    const { accessCode } = await response.json();
    return accessCode;
};
export const addPairRoomHistory = async ({ driver, navigator, timerDuration, timerRemainingTime, accessCode, }) => {
    await fetcher.post({
        url: `${API_URL}/${accessCode}/history`,
        body: JSON.stringify({ driver, navigator, timerDuration, timerRemainingTime }),
        errorMessage: '',
    });
};
export const updateTimerDuration = async ({ timerDuration, accessCode }) => {
    await fetcher.patch({
        url: `${API_URL}/${accessCode}/history/latest/timer-duration`,
        body: JSON.stringify({ timerDuration: Number(timerDuration) * 60 * 1000 }),
        errorMessage: '',
    });
    await fetcher.patch({
        url: `${API_URL}/${accessCode}/history/latest/timer-remaining-time`,
        body: JSON.stringify({ timerRemainingTime: Number(timerDuration) * 60 * 1000 }),
        errorMessage: '',
    });
};
export const updateRemainingTime = async ({ remainingTime, accessCode }) => {
    await fetcher.patch({
        url: `${API_URL}/${accessCode}/history/latest/timer-remaining-time`,
        body: JSON.stringify({ timerRemainingTime: remainingTime }),
        errorMessage: '',
    });
};
