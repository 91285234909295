import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { LuPlus } from 'react-icons/lu';
import Button from '@/components/common/Button/Button';
import Dropdown from '@/components/common/Dropdown/Dropdown/Dropdown';
import Input from '@/components/common/Input/Input';
import useInput from '@/hooks/common/useInput';
import { BUTTON_TEXT } from '@/constants/button';
import * as S from './AddReferenceForm.styles';
const AddReferenceForm = ({ categories, handleAddReferenceLink }) => {
    const { value, status, message, handleChange, resetValue } = useInput();
    const [isFooterOpen, setIsFooterOpen] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(null);
    const handleSubmit = (event) => {
        event.preventDefault();
        const category = currentCategory === '카테고리 없음' ? null : currentCategory;
        handleAddReferenceLink(value, category);
        resetValue();
    };
    const handleCategory = (option) => {
        setCurrentCategory(option);
    };
    const newCategories = [...categories, '카테고리 없음'];
    return isFooterOpen ? (_jsxs(S.ReferenceFormContainer, { children: [_jsx(Dropdown, { height: "", width: "17rem", options: newCategories, selectedOption: currentCategory || '카테고리 없음', placeholder: "\uCE74\uD14C\uACE0\uB9AC\uB97C \uC120\uD0DD\uD574\uC8FC\uC138\uC694.", onSelect: (option) => handleCategory(option), direction: "upper" }), _jsxs(S.Form, { onSubmit: handleSubmit, children: [_jsx(Input, { "$css": S.inputStyles, placeholder: "\uB9C1\uD06C\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694.", value: value, status: status, message: message, onChange: handleChange }), _jsxs(S.ButtonContainer, { children: [_jsx(Button, { type: "button", size: "sm", filled: false, rounded: true, onClick: () => setIsFooterOpen(false), children: BUTTON_TEXT.CANCEL }), _jsx(Button, { type: "submit", size: "sm", rounded: true, disabled: value === '' || status !== 'DEFAULT', children: BUTTON_TEXT.CONFIRM })] })] })] })) : (_jsxs(S.FooterButton, { onClick: () => setIsFooterOpen(true), children: [_jsx(LuPlus, {}), "\uB9C1\uD06C \uCD94\uAC00\uD558\uAE30"] }));
};
export default AddReferenceForm;
