import Body from '@/components/common/Modal/Body/Body';
import CloseButton from '@/components/common/Modal/CloseButton/CloseButton';
import Footer from '@/components/common/Modal/Footer/Footer';
import Header from '@/components/common/Modal/Header/Header';
import Layout from '@/components/common/Modal/Modal';
export const Modal = Object.assign(Layout, {
    CloseButton,
    Header,
    Body,
    Footer,
});
