import fetcher from '@/apis/fetcher';
import { ERROR_MESSAGES } from '@/constants/message';
const API_URL = process.env.REACT_APP_API_URL;
export const getCategories = async (accessCode) => {
    const response = await fetcher.get({
        url: `${API_URL}/${accessCode}/category`,
        errorMessage: ERROR_MESSAGES.GET_CATEGORIES,
    });
    return await response.json();
};
export const addCategory = async ({ category, accessCode }) => {
    const response = await fetcher.post({
        url: `${API_URL}/${accessCode}/category`,
        body: JSON.stringify({ value: category }),
        errorMessage: ERROR_MESSAGES.ADD_CATEGORY,
    });
    return await response.json();
};
export const deleteCategory = async ({ categoryName, accessCode }) => {
    await fetcher.delete({
        url: `${API_URL}/${accessCode}/category/${categoryName}`,
    });
};
export const updateCategory = async ({ previousCategoryName, updatedCategoryName, accessCode, }) => {
    await fetcher.patch({
        url: `${API_URL}/${accessCode}/category`,
        body: JSON.stringify({ previousCategoryName, updatedCategoryName }),
    });
};
