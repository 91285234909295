import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { IoIosCheckbox } from 'react-icons/io';
import { LuPlus } from 'react-icons/lu';
import Button from '@/components/common/Button/Button';
import Input from '@/components/common/Input/Input';
import ToolTipQuestionBox from '@/components/common/Tooltip/ToolTipQuestionBox';
import { PairRoomCard } from '@/components/PairRoom/PairRoomCard';
import TodoList from '@/components/PairRoom/TodoListCard/TodoList/TodoList';
import useInput from '@/hooks/common/useInput';
import useTodos from '@/queries/PairRoom/useTodos';
import { theme } from '@/styles/theme';
import * as S from './TodoListCard.styles';
const TodoListCard = ({ isOpen, toggleIsOpen }) => {
    const { accessCode } = useParams();
    const [isFooterOpen, setIsFooterOpen] = useState(false);
    const { handleAddTodos } = useTodos(accessCode || '');
    const { value, handleChange, resetValue } = useInput();
    const handleSubmit = (event) => {
        event.preventDefault();
        handleAddTodos(value);
        resetValue();
        setIsFooterOpen(false);
    };
    return (_jsx(S.Layout, { children: _jsxs(PairRoomCard, { children: [_jsx(PairRoomCard.Header, { icon: _jsx(IoIosCheckbox, { color: theme.color.primary[500] }), title: "\uD22C\uB450 \uB9AC\uC2A4\uD2B8", secondIcon: _jsx(ToolTipQuestionBox, { message: "\uBBF8\uC158\uC5D0 \uB300\uD55C todo \uB97C \uC791\uC131\uD574\uBCF4\uC138\uC694. \uC791\uC131\uD55C \uD22C\uB450\uB294 markdown \uC73C\uB85C \uBCF5\uC0AC\uAC00 \uAC00\uB2A5\uD569\uB2C8\uB2E4. " }), isOpen: isOpen, toggleIsOpen: toggleIsOpen }), _jsxs(S.Body, { "$isOpen": isOpen, children: [_jsx(TodoList, {}), _jsx(S.Footer, { children: isFooterOpen ? (_jsxs(S.Form, { onSubmit: handleSubmit, children: [_jsx(Input, { "$css": S.inputStyles, value: value, onChange: handleChange }), _jsxs(S.ButtonContainer, { children: [_jsx(Button, { type: "button", size: "sm", filled: false, rounded: true, onClick: () => setIsFooterOpen(false), children: "\uCDE8\uC18C" }), _jsx(Button, { type: "submit", size: "sm", rounded: true, disabled: value === '', children: "\uD655\uC778" })] })] })) : (_jsxs(S.FooterButton, { onClick: () => setIsFooterOpen(true), children: [_jsx(LuPlus, {}), "\uD22C\uB450 \uCD94\uAC00\uD558\uAE30"] })) })] })] }) }));
};
export default TodoListCard;
