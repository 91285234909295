import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import CheckBox from '@/components/common/CheckBox/CheckBox';
import useTodos from '@/queries/PairRoom/useTodos';
import * as S from './TodoItem.styles';
const TodoItem = ({ todo, isDraggedOver, onDragStart, onDragEnter, onDrop }) => {
    const { accessCode } = useParams();
    const { handleUpdateChecked, handleDeleteTodo } = useTodos(accessCode || '');
    const { id, isChecked, content } = todo;
    return (_jsxs(S.Layout, { "$isDraggedOver": isDraggedOver, draggable: true, onDragStart: () => onDragStart(id), onDragEnter: () => onDragEnter(id), onDragOver: (event) => event.preventDefault(), onDragEnd: onDrop, children: [_jsxs(S.TodoContainer, { children: [_jsx(CheckBox, { isChecked: isChecked, onClick: () => handleUpdateChecked(id) }), _jsx("p", { children: content })] }), _jsx(S.DeleteIcon, { onClick: () => handleDeleteTodo(id) })] }));
};
export default TodoItem;
