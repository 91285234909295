import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef } from 'react';
import * as S from '@/components/common/Dropdown/Dropdown/Dropdown.styles';
import HiddenDropdown from '@/components/common/Dropdown/HiddenDropdown/HiddenDropdown';
import useClickOutside from '@/hooks/common/useClickOutside';
import { theme } from '@/styles/theme';
const Dropdown = ({ placeholder, options, selectedOption = '', width = '100%', height = '4.8rem', direction = 'lower', onSelect, }) => {
    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    useClickOutside(dropdownRef, () => setIsOpen(false));
    const handleSelect = (option) => {
        onSelect(option);
        setIsOpen(false);
    };
    const toggleDropdown = (event) => {
        event.stopPropagation();
        setIsOpen((prev) => !prev);
    };
    return (_jsxs(S.Layout, { "$width": width, ref: dropdownRef, "$height": height, children: [_jsx(HiddenDropdown, { options: options, selectedOption: selectedOption, handleSelect: handleSelect }), _jsxs(S.DropdownContainer, { "$direction": direction, children: [_jsxs(S.OpenButton, { role: "listbox", filled: false, "$isSelected": !!selectedOption, "$isOpen": isOpen, onClick: toggleDropdown, children: [selectedOption || placeholder, _jsx(S.Icon, { "$isOpen": isOpen, size: theme.iconSize.md, "$direction": direction })] }), !options.some((option) => option === '') && isOpen && (_jsx(S.ItemList, { "$width": width, "$height": height, "$direction": direction, children: options.map((option, index) => (_jsx("li", { children: _jsx(S.Item, { filled: false, role: "option", "aria-selected": selectedOption === option, onClick: (event) => {
                                    event.stopPropagation();
                                    handleSelect(option);
                                }, children: option }) }, `${option}_${index}`))) }))] })] }));
};
export default Dropdown;
