import { useState } from 'react';
const useAutoMoveIndex = (defaultIndex, validationList, isTyping) => {
    const [index, setIndex] = useState(defaultIndex);
    const handleIndex = (nextIndex) => nextIndex > index && setIndex(nextIndex);
    if (!isTyping && validationList.some(Boolean)) {
        const nextIndex = defaultIndex + validationList.filter(Boolean).length;
        handleIndex(nextIndex);
    }
    return { moveIndex: index };
};
export default useAutoMoveIndex;
