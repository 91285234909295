import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import useToastStore from '@/stores/toastStore';
import { getTodos, addTodos, updateOrder, updateChecked, updateContents, deleteTodo } from '@/apis/todo';
const useTodos = (accessCode) => {
    const queryClient = useQueryClient();
    const { addToast } = useToastStore();
    const { data } = useQuery({
        queryKey: ['getTodos'],
        queryFn: () => getTodos(accessCode),
    });
    const { mutate: addTodosMutation } = useMutation({
        mutationFn: addTodos,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getTodos'] }),
        onError: (error) => addToast({ status: 'ERROR', message: error.message }),
    });
    const { mutate: updateContentsMutation } = useMutation({
        mutationFn: updateContents,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getTodos'] }),
        onError: (error) => addToast({ status: 'ERROR', message: error.message }),
    });
    const { mutate: updateOrderMutation } = useMutation({
        mutationFn: updateOrder,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getTodos'] }),
        onError: (error) => addToast({ status: 'ERROR', message: error.message }),
    });
    const { mutate: updateCheckedMutation } = useMutation({
        mutationFn: updateChecked,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getTodos'] }),
        onError: (error) => addToast({ status: 'ERROR', message: error.message }),
    });
    const { mutate: deleteTodoMutation } = useMutation({
        mutationFn: deleteTodo,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getTodos'] }),
        onError: (error) => addToast({ status: 'ERROR', message: error.message }),
    });
    const handleAddTodos = (content) => addTodosMutation({ content, accessCode });
    const handleUpdateContents = (todoId, contents) => updateContentsMutation({ todoId, contents });
    const handleUpdateOrder = (todoId, order) => updateOrderMutation({ todoId, order });
    const handleUpdateChecked = (todoId) => updateCheckedMutation({ todoId });
    const handleDeleteTodo = (todoId) => deleteTodoMutation({ todoId });
    return {
        todos: data || [],
        handleAddTodos,
        handleUpdateContents,
        handleUpdateOrder,
        handleUpdateChecked,
        handleDeleteTodo,
    };
};
export default useTodos;
