import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import DeleteButton from '@/components/PairRoom/PairListCard/DeleteButton/DeleteButton';
import Header from '@/components/PairRoom/PairListCard/Header/Header';
import PairListSection from '@/components/PairRoom/PairListCard/PairListSection/PairListSection';
import RoomCodeSection from '@/components/PairRoom/PairListCard/RoomCodeSection/RoomCodeSection';
import { PairRoomCard } from '@/components/PairRoom/PairRoomCard';
import * as S from './PairListCard.styles';
const PairListCard = ({ driver, navigator, roomCode, onRoomDelete }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen(!isOpen);
    return (_jsx(S.Layout, { "$isOpen": isOpen, onMouseOver: () => setIsOpen(true), onMouseLeave: () => setIsOpen(false), children: _jsxs(PairRoomCard, { children: [_jsx(Header, { isOpen: isOpen, toggleOpen: toggleOpen }), _jsxs(S.Sidebar, { children: [_jsx(RoomCodeSection, { isOpen: isOpen, roomCode: roomCode }), _jsx(PairListSection, { isOpen: isOpen, driver: driver, navigator: navigator }), _jsx(DeleteButton, { isOpen: isOpen, onRoomDelete: onRoomDelete })] })] }) }));
};
export default PairListCard;
