import { create } from 'zustand';
const useToastStore = create((set) => ({
    toastList: [],
    addToast: (toast) => {
        const id = Date.now();
        const toastItem = { ...toast, id, isOpen: true, isPush: false };
        set((state) => {
            return { toastList: [toastItem, ...state.toastList.map((item) => ({ ...item, isPush: true }))].slice(0, 3) };
        });
        setTimeout(() => {
            set((state) => ({
                toastList: state.toastList.map((item) => (item.id === id ? { ...item, isOpen: false } : item)),
            }));
            setTimeout(() => {
                set((state) => ({
                    toastList: state.toastList.filter((item) => item.id !== id),
                }));
            }, 750);
        }, 3000);
    },
}));
export default useToastStore;
