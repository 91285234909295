export const validateName = (name) => {
    if (name.trim() === '')
        return { status: 'ERROR', message: '값을 입력해주세요.' };
    if (name.length > 10)
        return { status: 'ERROR', message: '이름(또는 닉네임)은 10자 이하로 입력해주세요.' };
    return { status: 'DEFAULT', message: '' };
};
export const validateDuplicateName = (firstPairName, secondPairName) => {
    if (firstPairName.trim() !== '' && secondPairName.trim() !== '' && firstPairName.trim() === secondPairName.trim())
        return { status: 'ERROR', message: '중복된 이름(또는 닉네임)입니다. ' };
    return { status: 'DEFAULT', message: '' };
};
