import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FaRegPaste } from 'react-icons/fa6';
import useCopyClipBoard from '@/hooks/common/useCopyClipboard';
import * as S from './RoomCodeSection.styles';
const RoomCodeSection = ({ isOpen, roomCode }) => {
    const [, onCopy] = useCopyClipBoard();
    const handleCopyClipBoard = (text) => {
        onCopy(text);
    };
    return (_jsxs(S.Layout, { "$isOpen": isOpen, onClick: () => handleCopyClipBoard(roomCode), children: [isOpen && (_jsxs(S.RoomCodeWrapper, { children: [_jsx(S.RoomCodeTitle, { children: "\uBC29 \uCF54\uB4DC" }), _jsx(S.RoomCode, { children: roomCode })] })), _jsx(FaRegPaste, { size: "1.5rem" })] }));
};
export default RoomCodeSection;
