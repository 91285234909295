import fetcher from '@/apis/fetcher';
import { ERROR_MESSAGES } from '@/constants/message';
const API_URL = process.env.REACT_APP_API_URL;
export const getTodos = async (accessCode) => {
    const response = await fetcher.get({
        url: `${API_URL}/${accessCode}/todos`,
        errorMessage: ERROR_MESSAGES.GET_TODOS,
    });
    return await response.json();
};
export const addTodos = async ({ content, accessCode }) => {
    await fetcher.post({
        url: `${API_URL}/${accessCode}/todos`,
        body: JSON.stringify({ content }),
        errorMessage: ERROR_MESSAGES.ADD_TODO,
    });
};
export const updateContents = async ({ todoId, contents }) => {
    await fetcher.patch({
        url: `${API_URL}/todos/${todoId}/contents`,
        body: JSON.stringify({ contents }),
        errorMessage: ERROR_MESSAGES.UPDATE_TODO,
    });
};
export const updateOrder = async ({ todoId, order }) => {
    await fetcher.patch({
        url: `${API_URL}/todos/${todoId}/order`,
        body: JSON.stringify({ order }),
        errorMessage: ERROR_MESSAGES.UPDATE_TODO,
    });
};
export const updateChecked = async ({ todoId }) => {
    await fetcher.patch({
        url: `${API_URL}/todos/${todoId}/checked`,
        errorMessage: ERROR_MESSAGES.UPDATE_TODO,
    });
};
export const deleteTodo = async ({ todoId }) => {
    await fetcher.delete({
        url: `${API_URL}/todos/${todoId}`,
        errorMessage: ERROR_MESSAGES.DELETE_TODO,
    });
};
