import { useState } from 'react';
const useDragAndDrop = (list, handleOrder) => {
    const [dragItem, setDragItem] = useState(null);
    const [dragOverItem, setDragOverItem] = useState(null);
    const handleDragStart = (id) => setDragItem(list.find((item) => item.id === id) || null);
    const handleDragEnter = (id) => setDragOverItem(list.find((item) => item.id === id) || null);
    const handleDrop = (event) => {
        event.preventDefault();
        if (!dragItem || !dragOverItem || dragItem.id === dragOverItem.id)
            return;
        handleOrder(dragItem.id, dragOverItem.order);
        setDragItem(null);
        setDragOverItem(null);
    };
    return { dragItem, dragOverItem, handleDragStart, handleDragEnter, handleDrop };
};
export default useDragAndDrop;
