import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Input from '@/components/common/Input/Input';
import { Message } from '@/components/common/Input/Input.styles';
import CategoryItem from '@/components/PairRoom/ReferenceCard/CategoryFilter/CategoryItem';
import IconButton from '@/components/PairRoom/ReferenceCard/CategoryFilter/IconButton';
import useInput from '@/hooks/common/useInput';
import useDeleteCategory from '@/queries/PairRoom/category/useDeleteCategory';
import useGetCategories from '@/queries/PairRoom/category/useGetCategories';
import useUpdateCategory from '@/queries/PairRoom/category/useUpdateCategory';
import * as S from './CategoryFilter.styles';
const CategoryBox = ({ category, isChecked, handleSelectCategory }) => {
    const [isEditing, setIsEditing] = useState(false);
    const { value, handleChange, resetValue, message, status } = useInput(category);
    const { accessCode } = useParams();
    const { deleteCategoryMutation } = useDeleteCategory();
    const { updateCategoryMutation } = useUpdateCategory(() => resetValue());
    const { isCategoryExist } = useGetCategories(accessCode);
    const updateCategory = async (event) => {
        event.preventDefault();
        if (status === 'ERROR')
            return;
        await updateCategoryMutation({ updatedCategoryName: value, previousCategoryName: category, accessCode });
        setIsEditing(false);
        handleSelectCategory('전체');
    };
    const handleCancel = () => {
        setIsEditing(false);
        resetValue();
    };
    const validateCategoryName = (category) => {
        if (category.length >= 8)
            return {
                status: 'ERROR',
                message: '8자 이하로 입력해주세요',
            };
        if (isCategoryExist(category) || category === '전체')
            return {
                status: 'ERROR',
                message: '중복된 카테고리 입니다.',
            };
        return {
            status: 'DEFAULT',
            message: '',
        };
    };
    const handleEdit = () => setIsEditing(true);
    const deleteCategory = () => {
        deleteCategoryMutation({ categoryName: category, accessCode });
        if (!isChecked)
            return;
        handleSelectCategory('전체');
    };
    return (_jsx(_Fragment, { children: _jsx(S.CategoryBox, { onClick: () => {
                handleSelectCategory(category);
            }, children: isEditing ? (_jsxs(S.EditFrom, { onSubmit: (event) => updateCategory(event), children: [_jsxs(S.CategoryItemInputBox, { children: [_jsx(Input, { placeholder: "\uC218\uC815\uD560 \uCE74\uD14C\uACE0\uB9AC \uC774\uB984\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694.", value: value, onChange: (event) => {
                                    handleChange(event, validateCategoryName(event.target.value));
                                }, status: status }), _jsxs(S.CategoryIconsBox, { children: [_jsx(IconButton, { icon: "CHECK" }), _jsx(IconButton, { onClick: handleCancel, icon: "CANCEL" })] })] }), message && _jsx(Message, { "$status": status, children: message })] })) : (_jsxs(_Fragment, { children: [_jsx(CategoryItem, { id: category, isChecked: isChecked, category: category, handleSelectCategory: handleSelectCategory }), category !== '전체' && (_jsxs(S.CategoryIconsBox, { children: [_jsx(IconButton, { onClick: handleEdit, icon: "EDIT" }), _jsx(IconButton, { onClick: deleteCategory, icon: "DELETE" })] }))] })) }) }));
};
export default CategoryBox;
