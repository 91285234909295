import { useNavigate } from 'react-router-dom';
import useUserStatusStore from '@/stores/userStatusStore';
import { addSignUp } from '@/apis/oauth';
const useSignUpHandler = () => {
    const { setUserStatus } = useUserStatusStore();
    const navigate = useNavigate();
    const handleSignUp = async (username) => {
        await addSignUp(username);
        setUserStatus('SIGNED_IN');
        navigate('/main');
    };
    return { handleSignUp };
};
export default useSignUpHandler;
