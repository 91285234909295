import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as S from '@/pages/Main/Main.styles';
import { ScrollAnimationContainer } from '@/components/common/Animation/ScrollAnimationContainer';
import WaveBackground from '@/components/common/Background/WaveBackground';
import Button from '@/components/common/Button/Button';
import PairRoomCreateModal from '@/components/Main/PairRoomCreateModal/PairRoomCreateModal';
import PairRoomEntryModal from '@/components/Main/PairRoomEntryModal/PairRoomEntryModal';
import useModal from '@/hooks/common/useModal';
import usePreventBackNavigation from '@/hooks/common/usePreventBackNavigation';
const Main = () => {
    usePreventBackNavigation();
    const { isModalOpen: isPairRoomCreateModalOpen, openModal: openPairRoomCreateModal, closeModal: closePairRoomCreateModal, } = useModal();
    const { isModalOpen: isPairRoomEntryModalOpen, openModal: openPairRoomEntryModal, closeModal: closePairRoomEntryModal, } = useModal();
    return (_jsxs(_Fragment, { children: [_jsx(WaveBackground, {}), _jsxs(S.Layout, { children: [_jsx(ScrollAnimationContainer, { animationDirection: "right", children: _jsxs(S.TextContainer, { children: [_jsxs(S.TitleContainer, { children: [_jsxs(S.SubTitle, { children: [_jsx("span", { children: "\uD611\uC5C5" }), "\uACFC ", _jsx("span", { children: "\uC131\uC7A5" }), "\uC744 \uC704\uD55C", _jsx("br", {}), _jsx("span", { children: "\uD398\uC5B4\uD504\uB85C\uADF8\uB798\uBC0D-" })] }), _jsxs(S.Title, { children: ["\uCF54\uB529\uD574", _jsx("span", { children: "\uB4C0\uC624" })] })] }), _jsxs(S.Info, { children: ["\uCF54\uB529\uD574\uB4C0\uC624\uB294 \uD398\uC5B4\uD504\uB85C\uADF8\uB798\uBC0D\uC744 \uD1B5\uD574 \uB354 \uB098\uC740 \uACB0\uACFC\uB97C \uB9CC\uB4E4\uC5B4\uB0B4\uB294 \uAC83\uC744 \uBAA9\uD45C\uB85C \uD569\uB2C8\uB2E4.", _jsx("br", {}), "\uC9C1\uAD00\uC801\uC778 \uC778\uD130\uD398\uC774\uC2A4\uC640 \uC2E4\uC2DC\uAC04 \uD611\uC5C5 \uB3C4\uAD6C\uB85C, \uB204\uAD6C\uB098 \uC27D\uAC8C \uC0AC\uC6A9\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4."] })] }) }), _jsxs(S.ButtonContainer, { children: [_jsx(ScrollAnimationContainer, { animationDirection: "left", animationDelay: 0.3, children: _jsx(Button, { size: "xl", rounded: true, onClick: openPairRoomCreateModal, children: "\uBC29 \uB9CC\uB4E4\uAE30" }) }), _jsx(ScrollAnimationContainer, { animationDirection: "left", animationDelay: 0.5, children: _jsx(Button, { size: "xl", filled: false, rounded: true, onClick: openPairRoomEntryModal, children: "\uBC29 \uB4E4\uC5B4\uAC00\uAE30" }) })] }), _jsx(PairRoomCreateModal, { isOpen: isPairRoomCreateModalOpen, closeModal: closePairRoomCreateModal }), _jsx(PairRoomEntryModal, { isOpen: isPairRoomEntryModalOpen, closeModal: closePairRoomEntryModal })] })] }));
};
export default Main;
