import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '@/components/common/Spinner/Spinner';
import PairListCard from '@/components/PairRoom/PairListCard/PairListCard';
import PairRoleCard from '@/components/PairRoom/PairRoleCard/PairRoleCard';
import ReferenceCard from '@/components/PairRoom/ReferenceCard/ReferenceCard';
import TimerCard from '@/components/PairRoom/TimerCard/TimerCard';
import TodoListCard from '@/components/PairRoom/TodoListCard/TodoListCard';
import useAddPairRoomHistory from '@/queries/Main/useAddPairRoomHistory';
import useGetPairRoomHistory from '@/queries/Main/useGetPairRoomHistory';
import useUpdateRemainingTime from '@/queries/PairRoomOnboarding/useUpdateRemainingTime';
import * as S from './PairRoom.styles';
const PairRoom = () => {
    const { accessCode } = useParams();
    const [driver, setDriver] = useState('');
    const [navigator, setNavigator] = useState('');
    const { driver: latestDriver, navigator: latestNavigator, timerDuration, timerRemainingTime, isFetching, } = useGetPairRoomHistory(accessCode || '');
    const { handleAddPairRoomHistory } = useAddPairRoomHistory(accessCode || '');
    const { handleUpdateRemainingTime } = useUpdateRemainingTime(accessCode || '');
    useEffect(() => {
        setDriver(latestDriver);
        setNavigator(latestNavigator);
    }, [latestDriver, latestNavigator]);
    const [isCardOpen, setIsCardOpen] = useState(false);
    const toggleIsCardOpen = () => setIsCardOpen((prev) => !prev);
    const handleSwap = () => {
        handleAddPairRoomHistory(navigator, driver, timerDuration, timerDuration);
        setDriver(navigator);
        setNavigator(driver);
    };
    if (isFetching) {
        return (_jsx(S.Layout, { children: _jsx(Spinner, {}) }));
    }
    return (_jsxs(S.Layout, { children: [_jsx(PairListCard, { driver: driver, navigator: navigator, roomCode: accessCode || '', onRoomDelete: () => { } }), _jsxs(S.Container, { children: [_jsx(PairRoleCard, { driver: driver, navigator: navigator }), _jsx(TimerCard, { defaultTime: timerDuration, defaultTimeleft: timerRemainingTime, onTimerStop: handleSwap, onUpdateTimeLeft: handleUpdateRemainingTime })] }), _jsxs(S.Container, { children: [_jsx(TodoListCard, { isOpen: !isCardOpen, toggleIsOpen: toggleIsCardOpen }), _jsx(ReferenceCard, { accessCode: accessCode || '', isOpen: isCardOpen, toggleIsOpen: toggleIsCardOpen })] })] }));
};
export default PairRoom;
