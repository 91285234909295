import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createPortal } from 'react-dom';
import useEscapeKey from '@/hooks/common/useEscapeKey';
import useFocusTrap from '@/hooks/common/useFocusTrap';
import usePreventScroll from '@/hooks/common/usePreventScroll';
import * as S from './Modal.styles';
const Modal = ({ isOpen, close, size = 'md', height = '', position = 'CENTER', shadow = true, animation = true, backdropType = 'OPAQUE', children, }) => {
    const modalRef = useFocusTrap(isOpen);
    useEscapeKey(isOpen, close);
    usePreventScroll(isOpen);
    if (!isOpen)
        return null;
    return createPortal(_jsxs(S.Layout, { ref: modalRef, "$position": position, children: [_jsx(S.Backdrop, { onClick: close, "$backdropType": backdropType }), _jsx(S.Container, { "$size": size, "$height": height, "$position": position, "$shadow": shadow, "$animation": animation, children: children })] }), document.body);
};
export default Modal;
