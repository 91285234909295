import styled from 'styled-components';
export const Layout = styled.div `
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  position: fixed;
  top: 9rem;
  right: 2rem;
  z-index: 9999;
`;
