import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogoIconWithTitle } from '@/assets';
import Spinner from '@/components/common/Spinner/Spinner';
import useUserStatusStore from '@/stores/userStatusStore';
import { getSignInCallback } from '@/apis/oauth';
import * as S from './Callback.styles';
const Callback = () => {
    const navigate = useNavigate();
    const hasCalledBack = useRef(false);
    const { setUserStatus } = useUserStatusStore();
    const handleCallBack = async () => {
        if (hasCalledBack.current)
            return;
        hasCalledBack.current = true;
        const response = await getSignInCallback();
        if (response.signedUp) {
            setUserStatus('SIGNED_IN');
            navigate('/main');
        }
        else {
            navigate('/sign-up');
        }
    };
    useEffect(() => {
        handleCallBack();
    }, []);
    return (_jsxs(S.Layout, { children: [_jsx(S.LogoIconWithTitle, { src: LogoIconWithTitle, alt: "logo" }), _jsx(S.Title, { children: "\uB85C\uADF8\uC778 \uC911\uC785\uB2C8\uB2E4! \uC7A0\uC2DC\uB9CC \uAE30\uB2E4\uB824\uC8FC\uC138\uC694 \u263A\uFE0F" }), _jsx(Spinner, { size: "md" })] }));
};
export default Callback;
