import { jsx as _jsx } from "react/jsx-runtime";
import { createPortal } from 'react-dom';
import Toast from '@/components/common/Toast/Toast';
import useToastStore from '@/stores/toastStore';
import * as S from './ToastList.styles';
const ToastList = () => {
    const { toastList } = useToastStore();
    return createPortal(_jsx(S.Layout, { children: toastList.map((item) => (_jsx(Toast, { isOpen: item.isOpen, isPush: item.isPush, message: item.message, status: item.status }, item.id))) }), document.body);
};
export default ToastList;
