import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import Button from '@/components/common/Button/Button';
import { Modal } from '@/components/common/Modal';
import * as S from './PairRoomCreateModal.styles';
const PairRoomCreateModal = ({ isOpen, closeModal }) => {
    // const handleSuccess = () => setStatus('COMPLETE');
    // const { addPairRoom, accessCode, isPending } = useAddPairRoom(handleSuccess);
    // const createPairRoom = (firstPair: string, secondPair: string) => addPairRoom({ firstPair, secondPair });
    // if (isPending)
    //   return (
    //     <Modal isOpen={isOpen} close={handleClose} size="60rem">
    //       <Modal.CloseButton close={handleClose} />
    //       <Modal.Header title="잠시만 기다려주세요..." subTitle="페어룸 생성 중입니다!" />
    //       <Modal.Body>
    //         <Spinner />
    //       </Modal.Body>
    //     </Modal>
    //   );
    return (_jsxs(Modal, { isOpen: isOpen, close: closeModal, size: "60rem", children: [_jsx(Modal.CloseButton, { close: closeModal }), _jsx(Modal.Header, { title: "\uD398\uC5B4\uB8F8 \uC120\uD0DD", subTitle: "\uC5B4\uB5A4 \uBC29\uC2DD\uC73C\uB85C \uD398\uC5B4\uB8F8\uC744 \uB9CC\uB4E4\uAE4C\uC694?" }), _jsxs(S.Layout, { children: [_jsx(Link, { to: "/onboarding?mission=false", children: _jsx(Button, { css: S.buttonStyles, color: "secondary", filled: false, children: "\uADF8\uB0E5 \uC2DC\uC791\uD560\uB798\uC694" }) }), _jsx(Link, { to: "/onboarding?mission=true", children: _jsx(Button, { css: S.buttonStyles, color: "secondary", children: "\uBBF8\uC158\uACFC \uD568\uAED8 \uC2DC\uC791\uD560\uB798\uC694" }) })] })] }));
};
export default PairRoomCreateModal;
