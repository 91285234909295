import { useEffect, useRef, useState } from 'react';
export const useScrollAnimation = (options = {}) => {
    const [isInViewport, setIsInViewport] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        if (!ref.current)
            return;
        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsInViewport(true);
                }
                else {
                    setIsInViewport(false);
                }
            });
        };
        const observerOptions = { ...options };
        const observer = new IntersectionObserver(callback, observerOptions);
        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [options]);
    return { isInViewport, ref };
};
