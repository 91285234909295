import { useMutation } from '@tanstack/react-query';
import { updateRemainingTime } from '@/apis/pairRoom';
const useUpdateRemainingTime = (accessCode) => {
    const { mutate } = useMutation({
        mutationFn: updateRemainingTime,
    });
    const handleUpdateRemainingTime = (remainingTime) => mutate({ remainingTime, accessCode });
    return { handleUpdateRemainingTime };
};
export default useUpdateRemainingTime;
