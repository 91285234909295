import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GithubLogoWhite, LogoIconWithTitle } from '@/assets';
import * as S from '@/pages/Landing/Landing.styles';
import { ScrollAnimationContainer } from '@/components/common/Animation/ScrollAnimationContainer';
import Button from '@/components/common/Button/Button';
import useUserStatusStore from '@/stores/userStatusStore';
import usePreventBackNavigation from '@/hooks/common/usePreventBackNavigation';
import useSignInHandler from '@/hooks/member/useSignInHandler';
import useTitleTime from '@/hooks/title/useTitleTime';
const Landing = () => {
    const { userStatus } = useUserStatusStore();
    const { handleSignInGithub } = useSignInHandler();
    const navigate = useNavigate();
    useTitleTime();
    usePreventBackNavigation();
    useEffect(() => {
        if (userStatus === 'SIGNED_IN') {
            navigate('/main');
        }
    }, [userStatus]);
    return (_jsxs(S.Layout, { children: [_jsx(ScrollAnimationContainer, { animationDirection: "right", children: _jsx(S.SubTitle, { children: "\uB2F9\uC2E0\uC758 \uCCAB \uBC88\uC9F8 \uD398\uC5B4 \uD504\uB85C\uADF8\uB798\uBC0D," }) }), _jsx(ScrollAnimationContainer, { animationDirection: "right", animationDelay: 0.75, children: _jsx(S.Logo, { src: LogoIconWithTitle, alt: "logo" }) }), _jsxs(S.ButtonContainer, { children: [_jsx(ScrollAnimationContainer, { animationDirection: "top", animationDelay: 2, children: _jsxs(Button, { css: S.GithubLoginButton, size: "xl", filled: false, onClick: handleSignInGithub, children: [_jsx("img", { src: GithubLogoWhite, alt: "github logo" }), "Github\uB85C \uB85C\uADF8\uC778"] }) }), _jsx(ScrollAnimationContainer, { animationDirection: "top", animationDelay: 2.1, children: _jsx(Button, { size: "xl", filled: false, onClick: () => navigate('/main'), children: "\uD68C\uC6D0\uAC00\uC785 \uC5C6\uC774 \uC0AC\uC6A9\uD558\uAE30" }) })] })] }));
};
export default Landing;
