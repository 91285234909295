import { useEffect, useRef } from 'react';
const FOCUSABLE_SELECTORS = 'button:not([disabled]), input:not([disabled]), select:not([disabled], textarea:not([disabled]), a[href], [tabindex]:not([tabindex="-1"])';
const useFocusTrap = (isOpen) => {
    const mainRef = useRef(null);
    const focusableElements = useRef([]);
    useEffect(() => {
        if (!isOpen || !mainRef.current)
            return;
        focusableElements.current = Array.from(mainRef.current.querySelectorAll(FOCUSABLE_SELECTORS));
        if (focusableElements.current.length === 0)
            return;
        const moveFocusIndexPrev = (currentIndex) => currentIndex === 0 ? focusableElements.current.length - 1 : currentIndex - 1;
        const moveFocusIndexNext = (currentIndex) => currentIndex === focusableElements.current.length - 1 ? 0 : currentIndex + 1;
        const handleKeyPress = (event) => {
            if (event.key === 'Tab') {
                event.preventDefault();
                const currentIndex = focusableElements.current.findIndex((el) => el === document.activeElement);
                const nextIndex = event.shiftKey ? moveFocusIndexPrev(currentIndex) : moveFocusIndexNext(currentIndex);
                focusableElements.current[nextIndex].focus();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => document.removeEventListener('keydown', handleKeyPress);
    }, [isOpen, mainRef]);
    return mainRef;
};
export default useFocusTrap;
