import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/font.css';
if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ['localhost:3001', 'https://coduo.site', /^\/api\//],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
    if (process.env.GA_TRACKING_ID)
        ReactGA.initialize(process.env.GA_TRACKING_ID);
}
ReactDOM.createRoot(document.getElementById('root')).render(_jsx(React.StrictMode, { children: _jsx(App, {}) }));
