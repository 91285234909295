import styled, { css, keyframes } from 'styled-components';
const slideIn = keyframes `
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;
const slideOut = keyframes `
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
`;
const pushDown = keyframes `
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0);
  }
`;
const backgroundMapper = {
    SUCCESS: css `
    background-color: ${({ theme }) => theme.color.success[500]};
  `,
    INFO: css `
    background-color: ${({ theme }) => theme.color.info[500]};
  `,
    WARNING: css `
    background-color: ${({ theme }) => theme.color.warning[500]};
  `,
    ERROR: css `
    background-color: ${({ theme }) => theme.color.danger[500]};
  `,
};
export const Layout = styled.div `
  display: flex;
  align-items: center;

  width: 30rem;
  min-height: 5rem;
  padding: 1.2rem 1.8rem;
  border-radius: 1.5rem;

  color: ${({ theme }) => theme.color.black[10]};
  font-size: ${({ theme }) => theme.fontSize.md};
  line-height: 1.5;

  animation:
    ${({ $isOpen }) => ($isOpen ? slideIn : slideOut)} 0.8s none,
    ${({ $isPush }) => $isPush && pushDown} 0.5s none;

  ${({ $status }) => backgroundMapper[$status]};
`;
