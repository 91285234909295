import styled from 'styled-components';
export const Layout = styled.div `
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;
export const TitleContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const Title = styled.div `
  color: ${({ theme }) => theme.color.primary[700]};
  font-size: ${({ theme }) => theme.fontSize.h5};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;
export const SubTitle = styled.div `
  color: ${({ theme }) => theme.color.primary[600]};
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`;
export const InputContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
