import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import { IoIosLink } from 'react-icons/io';
import { css } from 'styled-components';
import Button from '@/components/common/Button/Button';
import Input from '@/components/common/Input/Input';
import { Message } from '@/components/common/Input/Input.styles';
import { Modal } from '@/components/common/Modal';
import ToolTipQuestionBox from '@/components/common/Tooltip/ToolTipQuestionBox';
import { PairRoomCard } from '@/components/PairRoom/PairRoomCard';
import AddReferenceForm from '@/components/PairRoom/ReferenceCard/AddReferenceForm/AddReferenceForm';
import CategoryFilter from '@/components/PairRoom/ReferenceCard/CategoryFilter/CategoryFilter';
import ReferenceList from '@/components/PairRoom/ReferenceCard/ReferenceList/ReferenceList';
import useInput from '@/hooks/common/useInput';
import useModal from '@/hooks/common/useModal';
import useAddCategory from '@/queries/PairRoom/category/useAddCategory';
import useGetCategories from '@/queries/PairRoom/category/useGetCategories';
import useReferenceLinks from '@/queries/PairRoom/useReferenceLinks';
import { theme } from '@/styles/theme';
import * as S from './ReferenceCard.styles';
const ReferenceCard = ({ accessCode, isOpen, toggleIsOpen }) => {
    const { categories, categoryRecord, isCategoryExist } = useGetCategories(accessCode);
    const [selectedCategory, setSelectedCategory] = useState('전체');
    const handleSelectCategory = (category) => {
        setSelectedCategory(category);
    };
    const { referenceLinks, handleAddReferenceLink, handleDeleteReferenceLink } = useReferenceLinks(accessCode, selectedCategory);
    const { isModalOpen, openModal, closeModal } = useModal();
    const { value, handleChange, resetValue, message, status } = useInput('');
    const { addCategory } = useAddCategory();
    const validateCategoryName = (category) => {
        if (category.length > 8)
            return {
                status: 'ERROR',
                message: '8자 이하로 입력해주세요',
            };
        if (isCategoryExist(category))
            return {
                status: 'ERROR',
                message: '중복된 카테고리 입니다.',
            };
        return {
            status: 'DEFAULT',
            message: '',
        };
    };
    const closeCategoryModal = () => {
        closeModal();
        resetValue();
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Modal, { isOpen: isModalOpen, close: closeCategoryModal, size: "45rem", children: [_jsx(Modal.Header, { children: _jsxs(S.CategoryModalHeader, { children: [_jsx(FaFilter, {}), _jsx("p", { children: "\uCE74\uD14C\uACE0\uB9AC \uC120\uD0DD" })] }) }), _jsx(Modal.CloseButton, { close: closeCategoryModal }), _jsx(Modal.Body, { children: _jsx(CategoryFilter, { categories: categoryRecord, selectedCategory: selectedCategory, handleSelectCategory: handleSelectCategory }) }), _jsxs(S.AddNewCategoryBox, { onSubmit: (event) => {
                            event.preventDefault();
                            if (status === 'ERROR')
                                return;
                            addCategory({ category: value, accessCode });
                            resetValue();
                        }, children: [_jsxs(S.AddNewCategoryInputBox, { children: [_jsx(Input, { value: value, placeholder: "\uC0C8\uB85C\uC6B4 \uCE74\uD14C\uACE0\uB9AC\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694.", onChange: (event) => handleChange(event, validateCategoryName(event.target.value)), status: status, "$css": css `
                width: 100%;
                border: none;

                font-size: ${({ theme }) => theme.fontSize.md};
              ` }), _jsx(Button, { size: "sm", disabled: status === 'ERROR' || value === '', children: "\uCD94\uAC00" })] }), _jsx(Message, { "$status": status, children: message })] })] }), _jsx(S.Layout, { children: _jsxs(PairRoomCard, { children: [_jsx(PairRoomCard.Header, { icon: _jsx(IoIosLink, { color: theme.color.primary[500] }), title: "\uB9C1\uD06C", secondIcon: _jsx(ToolTipQuestionBox, { direction: isOpen ? 'bottom' : 'top', message: "\uD398\uC5B4\uD504\uB85C\uADF8\uB798\uBC0D \uB3C4\uC911 \uB3C4\uC6C0\uC774 \uB418\uC5C8\uB358 \uB808\uD37C\uB7F0\uC2A4 \uB9C1\uD06C\uB4E4\uC744 \uC800\uC7A5\uD574\uBCF4\uC138\uC694 \u263A\uFE0F" }), isOpen: isOpen, toggleIsOpen: toggleIsOpen, children: _jsxs(S.CategoryBox, { children: [selectedCategory && (_jsx(Button, { onClick: (event) => {
                                            event.stopPropagation();
                                        }, rounded: true, size: "sm", css: css `
                    width: fit-content;
                    min-width: 6rem;
                    padding: 0 1rem;
                  `, children: selectedCategory })), _jsxs(Button, { onClick: (event) => {
                                            event.stopPropagation();
                                            openModal();
                                        }, filled: false, rounded: true, size: "sm", css: css `
                  display: flex;
                  gap: 0.5rem;

                  width: fit-content;
                  padding: 0 1rem;
                `, children: [_jsx(FaFilter, {}), "\uCE74\uD14C\uACE0\uB9AC \uAD00\uB9AC"] })] }) }), _jsxs(S.Body, { "$isOpen": isOpen, children: [_jsx(ReferenceList, { referenceLinks: referenceLinks, onDeleteReferenceLink: handleDeleteReferenceLink }), _jsx(S.Footer, { children: _jsx(AddReferenceForm, { categories: categories, handleAddReferenceLink: handleAddReferenceLink }) })] })] }) })] }));
};
export default ReferenceCard;
